/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../CafForm.scss';
import VodafoneImg from '../../../../../../assets/images/Vodaphone.png';
import IdeaImg from '../../../../../../assets/images/idea.png';
import {
  useExcelJsonList,
  useLoadRomsFormFieldListData,
  useLoadFieldValues,
} from '../../../../../../hooks/form.hooks';
import { loadImageBlob } from '../../../../../../utils/aws.util';
import { useAuthContext } from '../../../../../../context/auth.context';
import { Spinner, Center, useTheme } from '@chakra-ui/react';
import { JsonToTable } from 'react-json-to-table';
import { encode } from '../../cafCrf.util';

const CafForm = ({ formData, romsActivity, submittedForms }) => {
  const [cafData, setCafData] = useState(false);
  const [cafDataupdated, setUpdatedCafData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [acctMngSignImageData, setacctMngSignImageData] = useState(null);
  const [excelJson, setexcelJson] = useState(null);

  const { mutate } = useLoadRomsFormFieldListData();
  const { mutate: loadOrderFormValues } = useLoadFieldValues();
  const { mutate: loadExcelJsonValues } = useExcelJsonList();
  let componentRef = React.useRef();
  const chakraTheme = useTheme();

  const {
    state: { authData },
  } = useAuthContext();

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: authData.organization_id,
          account_id: authData.account_id,
          workforce_id: authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
      },
      {
        onSuccess: async data => {
          let finalFields = data;
          setCafData(false);
          setUpdatedCafData(finalFields);
        },
        onError: async err => {
          console.log('error occurred while loading fields', err);
        },
      }
    );
  };

  const fetchOrderFormValues = () => {
    let orderForm = submittedForms.find(
      item => item.form_id === romsActivity.order_form_id
    );
    loadOrderFormValues(
      {
        form_id: romsActivity?.order_form_id,
        form_transaction_id: !!orderForm
          ? orderForm.data_form_transaction_id
          : 0,
      },
      {
        onSuccess: async res => {
          let ExcelIndex = res[0].activity_inline_data.findIndex(
            res1 => res1.field_id === 11182
          );
          let excelUrl;
          if (ExcelIndex > -1) {
            excelUrl = res[0].activity_inline_data[ExcelIndex].field_value;
          }
          if (!!excelUrl) {
            loadExcelJsonValues(
              { bucket_url: excelUrl },
              {
                onSuccess: async result => {
                  setexcelJson(JSON.parse(result));
                },
              }
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    setCafData(true);
    fetchFieldListData();
    fetchOrderFormValues();
  }, []);

  useEffect(() => {
    let data = cafDataupdated;
    if (!!data) {
      if (!!data[7505].value) {
        // customer company seal
        let custCompanySeal = data[7505].value;
        loadImageBlob(custCompanySeal, (data, type) => {
          setImageData(data);
          setImageType(type);
        });
      }
      if (!!data[7127].value) {
        // manager sign url
        let acctManagerSignUrl = data[7127].value;
        loadImageBlob(acctManagerSignUrl, (data, type) => {
          setacctMngSignImageData(data);
        });
      }
    }
  }, [cafDataupdated]);

  let cafContentView = '';
  if (!!cafDataupdated) {
    let customerSignSplit =
      !!cafDataupdated[7124].value && cafDataupdated[7124].value.length > 0
        ? cafDataupdated[7124].value.split(' ')
        : '';
    let firstCaps;
    let secondBold;
    let Signcss;
    if (customerSignSplit.length > 1) {
      firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase();
      secondBold = customerSignSplit['1'];
      Signcss = 'signate_img input_sign';
    } else {
      firstCaps = customerSignSplit[0];
      secondBold = '';
      Signcss = 'signate_img input_sign under_line';
    }
    cafContentView = (
      <div>
        <div
          style={{ textAlign: 'left' }}
          className="tab_content"
          ref={el => (componentRef = el)}
        >
          <page size="A4">
            <div className="section1">
              <div className="section1_top">
                <div className="section1_top_col">
                  <p className="pad_l_r10 over_hid">
                    <img src={VodafoneImg} alt="voda1" />
                    <img src={IdeaImg} alt="idea1" className="pullr" />
                  </p>
                  <p className="customhed">Customer Application Form</p>
                  <div className="empty1"></div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <p className="vodabar">
                    Vodafone Idea Business Services
                    <br />
                    Domestic MPLS Service
                  </p>
                  <div className="signate float_img">
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                        {/* <img src={cafDataupdated[5972].value} alt={cafDataupdated[5972].value}/> */}
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2">
              <div className="font8">
                <p className="over_hid">
                  <img src={VodafoneImg} alt="voda2" />
                  <img src={IdeaImg} alt="idea2" className="pullr" />
                </p>
                <p className="customfom">
                  Customer Application Form - Domestic MPLS Service
                </p>
                <div className="w_85">
                  <div className="over_hid">
                    <div className="pd_t5">
                      <strong>Vodafone Idea Business Servies</strong>{' '}
                      <span className="pad_l10">
                        Kindly fill the form in BLOCK letters
                      </span>
                      <div className="caf">
                        CAF ID
                        <input
                          className="form_col"
                          type="text"
                          name=""
                          value={cafDataupdated[6988].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="over_hid mar_t-5">
                    <div className="pd_t5">
                      <strong>Company(Customer) Name</strong>{' '}
                      <span className="pad_l10">
                        <input
                          className="form_col_input input_red"
                          type="text"
                          name="CompanyName_CD"
                          value={cafDataupdated[6989].value}
                          readOnly
                        />
                      </span>
                      <div className="caf_inner">
                        Account Code
                        <input
                          className="form_col no_top"
                          type="text"
                          name="AccountCode_MPLS"
                          value={cafDataupdated[6990].value}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <p className="sign">
                    <strong>Authorised Signatory Details</strong>
                  </p>
                </div>
                <div className="over_hid auth">
                  <div className="fleft width45">
                    Name
                    <input
                      className="form_col width85"
                      type="text"
                      name="Name_CD"
                      value={cafDataupdated[7245].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Contact No:
                    <input
                      className="form_col width59"
                      type="text"
                      name="ContactNo_CD"
                      value={cafDataupdated[7246].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width30">
                    Email:
                    <input
                      className="form_col width80"
                      type="text"
                      name="Email_CD"
                      value={cafDataupdated[7247].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="font5">
                  I/We hereby order for VIL MPLS Service subject to terms and
                  conditions as described in the service order form. We furnish
                  the necessary details as follow.
                </p>
                <p className="ligthbg">BILLING ADDRESS</p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name="ContactPersonname_CD"
                    value={cafDataupdated[6997].value}
                    readOnly
                  />
                </p>
                <div className="auth over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name="Designation_CD"
                      value={cafDataupdated[6998].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name="DeptName_CD"
                      value={cafDataupdated[6999].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name="Address_CD"
                    value={cafDataupdated[7000].value}
                    readOnly
                  />
                </div>
                <div className="auth pad_b5">
                  <span className="width54px"> </span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
                <div className="auth over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name="City_CD"
                      value={cafDataupdated[7001].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name="Pincode_CD"
                      value={cafDataupdated[7002].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name="Telephone_CD"
                      value={cafDataupdated[7003].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name="Landmark_CD"
                      value={cafDataupdated[7004].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name="FaxNo_CD"
                      value={cafDataupdated[7005].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name="ContactEmail_CD"
                      value={cafDataupdated[7006].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name="MobileNo_AltNo_CD"
                      value={cafDataupdated[7007].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="ligthbg">INSTALLATION ADDRESS</p>
                <p className="auth">
                  Contact Person name
                  <input
                    className="form_col width60"
                    type="text"
                    name="ContactPerson_FR"
                    value={cafDataupdated[7010].value}
                    readOnly
                  />
                </p>
                <div className="auth over_hid">
                  <div className="fleft width50">
                    Designation
                    <input
                      className="form_col width80"
                      type="text"
                      name="Designation_FR"
                      value={cafDataupdated[7011].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width50">
                    Dept. Name
                    <input
                      className="form_col width80"
                      type="text"
                      name="DeptName_FR"
                      value={cafDataupdated[7012].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth margin0">
                  <span className="width54px">Address</span>
                  <input
                    className="form_col width90"
                    type="text"
                    name="Address_FR"
                    value={cafDataupdated[7013].value}
                    readOnly
                  />
                </div>
                <div className="auth pad_b5">
                  <span className="width54px"> </span>
                  <input
                    className="form_col width90"
                    type="text"
                    name=""
                    value=""
                  />
                </div>
                <div className="auth over_hid">
                  <div className="fleft width50">
                    City/Village/Post office
                    <input
                      className="form_col width65"
                      type="text"
                      name="City_FR"
                      value={cafDataupdated[7014].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Pincode
                    <input
                      className="form_col width65"
                      type="text"
                      name="Pincode_FR"
                      value={cafDataupdated[7015].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width25">
                    Telephone
                    <input
                      className="form_col width65"
                      type="text"
                      name="Telephone_FR"
                      value={cafDataupdated[7016].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Landmark
                    <input
                      className="form_col width84"
                      type="text"
                      name="Landmark_FR"
                      value={cafDataupdated[7496].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    <span className="faxn">Fax No.</span>
                    <input
                      className="form_col width66"
                      type="text"
                      name="Fax_FR"
                      value={cafDataupdated[7017].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="auth over_hid">
                  <div className="fleft width58">
                    Contact Email Id
                    <input
                      className="form_col width77"
                      type="text"
                      name="ContactEmail_FR"
                      value={cafDataupdated[7018].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width42">
                    Mobile No / Alt No.
                    <input
                      className="form_col width66"
                      type="text"
                      name="MobileNo_Alt_FR"
                      value={cafDataupdated[7019].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width15">
                    GSTIN
                    {cafDataupdated[7201].value === 'GSTIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="GSTIN"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    UIN
                    {cafDataupdated[7201].value === 'UIN' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="UIN"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                  <div className="fleft width15">
                    GST_ISD
                    {cafDataupdated[7201].value === 'GST_ISD' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="GST_ISD"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                  </div>
                </div>
                <div className="check over_hid typecheck">
                  <div className="fleft width12 pad_t7">Customer Type</div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'Normal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Normal"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Normal
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'SEZ' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="SEZ"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SEZ
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'Diplomat' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Diplomat"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diplomat
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'Embassy' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Embassy"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Embassy
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'Consulate' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Consulate"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consulate
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value === 'Special Agency' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Special Agency"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Special Agency
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7026].value ===
                    'Person/Class of Persons' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Person/Class of Persons"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Person/className of Person
                  </div>
                </div>
                <p className="auth">
                  GSTIN / UIN / GST_ISD No
                  <input
                    className="form_col wid50"
                    type="text"
                    name="GSTIN_MPLS"
                    value={cafDataupdated[7020].value}
                    readOnly
                  />
                </p>
                <div className="auth">
                  <span className="width70px in_block">GST Reg. Address</span>
                  <input
                    className="form_col width78"
                    type="text"
                    name="GSTReg_Address_CD"
                    value={cafDataupdated[7021].value}
                    readOnly
                  />
                </div>
                <div className="auth">
                  <span className="width70px in_block">
                    in installation state
                  </span>
                  <input
                    className="form_col width78"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
                <div className="check over_hid typecheck gstcheck">
                  <div className="fleft width12 pad_t7">GST Customer Dept</div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'IT' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="IT"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Tech' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Tech"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Tech
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Cust Service' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Cust Service"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Cust Service
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Legal' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Legal"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Legal
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Finance"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finanace
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Purchase' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Purchase"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Purchase
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'SCM' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="SCM"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    SCM
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Delivery' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Delivery"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Delivey
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7027].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Others"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Other
                  </div>
                  <div className="fleft width12">
                    <input
                      className="form_col oth_gst"
                      type="text"
                      name="Other"
                      value={cafDataupdated[7047].value}
                      readOnly
                    />
                  </div>
                </div>
                <p className="auth namsupp">
                  Name of Supporting Document
                  <input
                    className="form_col wid50"
                    type="text"
                    name="Supporting Document"
                    value={cafDataupdated[7022].value}
                    readOnly
                  />
                </p>
                <p className="gsttext">
                  (GST Certificate, SEZ Certificate, Diplomat Crtificate)
                </p>
                <p className="ligthbg">CUSTOMER INFORMATION</p>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">Customer Type</div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'General' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="General"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    General
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'ISP' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="ISP"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    ISP
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'OSP' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="OSP"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    OSP
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'Telemarketer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Telemarketer"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Telemarketer
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'BPO' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="BPO"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    BPO
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7024].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Others"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t">
                    Customer Vertical Type
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Automotive' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Automotive"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Automotive
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Consumer Goods' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Consumer Goods"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Consumer Goods
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Diversified & Projects' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Diversified & Projects"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Diversified & Projects
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Education' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Education"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Education
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Engineering' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Engineering"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Engineering
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Finance' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Finance"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Finanace
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Government' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Government"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Governament
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Health Care' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Health Care"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Health Care
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Industry Associations' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Industry Associations"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Industry Associations
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'IT & Telecom' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="IT & Telecom"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    IT & Telecome
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Logistics' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Logistics"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Logistics
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value ===
                    'Manufacturing & Processing' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Manufacturing & Processing"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Manufacturing & Processing
                  </div>
                </div>
                <div className="check over_hid custype">
                  <div className="fleft width15_2 pad_7t"> </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Trading' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Trading"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Trading
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Services' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Services"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Services
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Projects EPC' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Projects EPC"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Projects EPC
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7025].value === 'Others' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Others"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Others
                  </div>
                </div>
                <p className="ligthbg">MPLS ORDER DETAILS</p>

                <div className="signate float_img">
                  {firstCaps !== '' ? (
                    <div style={{ padding: '10px' }} className={Signcss}>
                      {firstCaps + '  .  '}
                      <span className="under_line">{secondBold}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {imageData !== null && imageData !== '' ? (
                    <div style={{ padding: '10px' }} className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="check over_hid mpls">
                  <div className="fleft width70px pad_7t">
                    <strong>Type of Order</strong>
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7029].value === 'New' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Type of Order"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    New
                  </div>
                </div>
                <div className="over_hid mplstextin padb10">
                  <div className="fleft width70px padt5">
                    <strong>Feasibility ID (FR ID)</strong>
                  </div>
                  <div className="fleft width70">
                    <input
                      className="form_col width70"
                      type="text"
                      name="Feasibility_MPLS"
                      value={cafDataupdated[7030].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t"> MPLS Topology</div>
                  <div className="fleft width14 pad_7t"> MPLS-L3 </div>
                  <div className="fleft width16">
                    {cafDataupdated[7139].value === 'Hub & Spokes' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Hub & Spokes"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Hub & Spokes{' '}
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7139].value === 'Hubs & Spokes' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Hubs & Spokes"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Hubs & Spokes{' '}
                  </div>
                  <div className="fleft width14">
                    {cafDataupdated[7139].value === 'Mesh(Any-to-Any)' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Mesh(Any-to-Any)"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Mesh (Any-to-Any)
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7139].value === 'Other' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Other"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Other
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7139].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="None"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t"> </div>
                  <div className="fleft width14 pad_7t"> MPLS-L2 </div>
                  <div className="fleft width16">
                    {cafDataupdated[7140].value === 'Point to Point' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Point to Point{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7140].value === 'Point to Multipoint' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Point to Multipoint
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7140].value === 'Mesh(Any-to-Any)' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Mesh (Any-to-Any)
                  </div>
                  <div className="fleft width12">
                    {cafDataupdated[7140].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="None"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None
                  </div>
                </div>
                <div className="auth remarks">
                  <span className="width65px">Remarks, if other</span>
                  <input
                    className="form_col width83"
                    type="text"
                    name="Remarks, if other"
                    value={cafDataupdated[7031].value}
                  />
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t"> Type of site</div>
                  <div className="fleft width16">
                    {cafDataupdated[7032].value === 'Hub' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Hub"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Hub{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7032].value === 'Spoke' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Spoke"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Spoke{' '}
                  </div>
                  <div className="fleft mplstech">
                    {cafDataupdated[7032].value === 'Any-to-Any Site' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Any-to-Any Site"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Any-to-Any Site
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Site Identifier{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7033].value === 'Domestic' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Domestic"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Domestic{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7033].value === 'International' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="International"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    International{' '}
                  </div>
                  <div className="fleft mplstech">
                    {cafDataupdated[7033].value === 'India International' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="India International"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    India International
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Contract Period{' '}
                  </div>
                  <div className="fleft width16">
                    <input
                      className="form_col width90 input_red"
                      type="text"
                      name="ContractPeriod_MPLS"
                      value={cafDataupdated[7034].value}
                      readOnly
                    />
                    Months{' '}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    Billing Periodicity{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7035].value === 'Monthly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Monthly"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Monthly{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7035].value === 'BI-Monthly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="BI-Monthly"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Bi-Monthly{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7035].value === 'Quarterly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Quarterly"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Quarterly
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7035].value === 'Half-Yearly' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Half-Yearly"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Half-Yearly{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7035].value === 'Annually' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Annually"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Annually{' '}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width70px pad_7t">
                    {' '}
                    CPE Provided By{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7036].value === 'Vodafone Idea' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Vodafone Idea"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Vodafone Idea{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7036].value === 'Customer' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Customer"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Customer{' '}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width110px pad_7t">
                    {' '}
                    If Provided by Vodafone Idea{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7037].value === 'Rent' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Rent"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Rent{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7037].value === 'Sale' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="Sale"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    Sale{' '}
                  </div>
                  <div className="fleft width16">
                    {cafDataupdated[7037].value === 'None' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="None"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    None{' '}
                  </div>
                </div>
                <p className="gsttext">CPE Details</p>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="over_hid">
                <div className="">
                  <div className="pad_b5">
                    <p className="customfom">
                      Customer Application Form - Domestic MPLS Service
                    </p>
                  </div>
                  <div className="domestictable">
                    <table
                      width="70%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tr>
                        <td>Sr. No.</td>
                        <td>Type (Standard/Other)</td>
                        <td>Make</td>
                        <td>Model</td>
                        <td>Version</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>{cafDataupdated[13082].value}</td>
                        <td>{cafDataupdated[13083].value}</td>
                        <td>{cafDataupdated[13084].value}</td>
                        <td>{cafDataupdated[13085].value}</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>{cafDataupdated[13122].value}</td>
                        <td>{cafDataupdated[13123].value}</td>
                        <td>{cafDataupdated[13124].value}</td>
                        <td>{cafDataupdated[13125].value}</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width70px pad_t3">
                      {' '}
                      Service(s) Ordered{' '}
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7048].value === 'Layer 3 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 3 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 3 MPLS
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7048].value === 'Layer 2 MPLS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Layer 2 MPLS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Layer 2 MPLS
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7048].value === 'Remote Access' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Remote Access"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Remote Access
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7048].value === 'Multicast VPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Multicast VPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Multicast VPN
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7048].value === 'Inter-operator VPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Inter-operator VPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Inter-operator VPN
                    </div>
                  </div>
                  <div className="check over_hid pad_5t">
                    <div className="fleft width70px pad_7t"> </div>
                    <div className="fleft">
                      {cafDataupdated[7048].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Other"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Other
                    </div>
                    <div className="fleft width49 padl5">
                      <input
                        className="form_col inputext widthfull"
                        type="text"
                        name="Other22"
                        value={cafDataupdated[7049].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Burstable Bandwidth</strong>
                  </p>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Nth Percentile Required{' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7051].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Yes"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7051].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="No"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                    <div className="fleft pad_t3 width16">
                      {' '}
                      IF YES &nbsp;&nbsp; Value of N
                    </div>
                    <div className="fleft width35">
                      <input
                        className="form_col width50px"
                        type="text"
                        name=""
                        value={cafDataupdated[7052].value}
                        readOnly
                      />
                      (Enter 95 for 95th percentile billing)
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Maximum Port Speed (Bandwidth){' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7137].value === 'Gbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Gbps"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gpbs
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7137].value === 'Mbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Mbps"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Mbps
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7137].value === 'Kbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Kbps"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Kbps
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Bandwidth on Demand Bandwidth</strong>
                  </p>

                  <div className="signate float_img">
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Bandwidth on Demand Required{' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7057].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Yes"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7057].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="No"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Maximum Port Speed (Bandwidth){' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7138].value === 'Gbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Gbps1"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Gpbs
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7138].value === 'Mbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Mbps1"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Mbps
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7138].value === 'Kbps' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Kbps1"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Kbps
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Multi-VRF Billing</strong>
                  </p>
                  <div className="check over_hid pad_t10">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Multi-VRF Billing Required{' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7059].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Yes"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7059].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="No"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>Applicable if Remote Access is selected</strong>
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Remote Access Type{' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7061].value === 'SSL VPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="SSL VPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      SSL VPN
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7061].value === 'IPSeCVPN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="IPSeCVPN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      IPSeC VPN
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7061].value === 'Dial Up (ISDN)' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Dial Up (ISDN)"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Dail UP (ISDN)
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7061].value ===
                      '2G 3G 4G API Solution' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="2G 3G 4G API Solution"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      2G 3G 4G API Solution
                    </div>
                    <div className="fleft width16">
                      {cafDataupdated[7061].value === 'None' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="None"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      None
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Circuit ID of Hub location{' '}
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name="Circuit ID of Hub location"
                        value={cafDataupdated[7062].value}
                      />
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      VPN ID of Hub location{' '}
                    </div>
                    <div className="fleft width49">
                      <input
                        className="form_col widthfull"
                        type="text"
                        name="VPN ID of Hub location"
                        value={cafDataupdated[7063].value}
                      />
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>For SSL VPN</strong>
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3"> Offer Type </div>
                    <div className="fleft width25">
                      {cafDataupdated[7065].value ===
                      'Customer Self ServicePortal' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Customer Self ServicePortal"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customer Self Service Portal
                    </div>
                    <div className="fleft width25">
                      {cafDataupdated[7065].value ===
                      'Vodafone Idea Managed' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Vodafone Idea Managed"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Vodafone Idea Managed
                    </div>
                  </div>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Company Domain Name{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col width90"
                        type="text"
                        name="Company Domain Name_OD"
                        value={cafDataupdated[7066].value}
                      />
                    </div>
                    <div className="fleft width45">
                      {' '}
                      1. If customer does not have domain name, mention Company
                      Name
                      <br />
                      2. Login ID will be LoginName@Companydomainname.com{' '}
                    </div>
                  </div>
                  <div className="check over_hid">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Number of Concurrent SSL VPN Users{' '}
                    </div>
                    <div className="fleft width30">
                      <input
                        className="form_col width90"
                        type="text"
                        name="Number of Concurrent SSL VPN User_OD"
                        value={cafDataupdated[7067].value}
                      />
                    </div>
                    <div className="fleft width30 padt5">
                      {' '}
                      (Min 1 & Max 1000){' '}
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>For IP Sec VPN</strong>
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      IPSec Port Bandwidth{' '}
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90 "
                        type="text"
                        name="IPSec Port Bandwidth_OD"
                        value={cafDataupdated[7069].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30 padt5"> (Kbps) </div>
                  </div>
                  <div className="check over_hid padt5">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Connection Type{' '}
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7070].value === 'Single CPE' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Single CPE"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Single CPE
                    </div>
                    <div className="fleft width10">
                      {cafDataupdated[7070].value === 'Dual CPE' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Dual CPE"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Dual CPE
                    </div>
                  </div>
                  <p className="margin0">
                    <strong>For Dial UP VPN</strong>
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      {' '}
                      Dial Up Port Bandwidth
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90 "
                        type="text"
                        name="Dial Up Port Bandwidth_OD"
                        value={cafDataupdated[7072].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 padt5"> (Kbps) </div>
                    <div className="fleft padt5"> (Multiple of 64 Kbps) </div>
                  </div>
                  <p className="margin0">
                    <strong>For 2G 3G 4G API solution</strong>
                  </p>
                  <div className="check over_hid pad_t6">
                    <div className="fleft width170px pad_t3">
                      Port Bandwidth
                    </div>
                    <div className="fleft width20">
                      <input
                        className="form_col width90 "
                        type="text"
                        name="Port Bandwidth_OD"
                        value={cafDataupdated[7074].value}
                      />
                    </div>
                    <div className="fleft width10 padt5"> (Kbps) </div>
                    <div className="fleft padt5 font9">
                      {' '}
                      (Choose from - 64 Kbps, 128 Kbps, 258 Kbps, 512 Kbps, 1024
                      Kbps, 2048 Kbps){' '}
                    </div>
                  </div>
                  <div className="check over_hid padt5">
                    <div className="fleft width170px pad_t3"> Site Id </div>
                    <div className="fleft width75">
                      {cafDataupdated[7075].value ===
                      'H1 (for Hub1),S1H1 (for Spoke1 connecting to Hub1)' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Site Id_1"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      H1 (for Hub1), S1H1 (for Spoke1 connecting to Hub1)
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Last Mile details{' '}
                    </div>
                    <div className="fleft width6 pd_t3"> Media</div>
                    <div className="fleft width6">
                      {cafDataupdated[7076].value === 'Copper' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Copper"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Copper
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7076].value === 'Fiber' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Fiber"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Fiber
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7076].value === 'Wireless' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Wireless"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Wireless
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Primary LM Technology{' '}
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'TDM' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="TDM"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      TDM
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'CEN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="CEN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CEN
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'EoSDH' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="EoSDH"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      EoSDH
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'BWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="BWA"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BWA
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'Rdio P2p' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Rdio P2p"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Radio P2P
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'ISDN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="ISDN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISDN
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === '3G' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="3G"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      3G
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7077].value === 'GPRS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="GPRS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      GPRS
                    </div>
                    <div className="fleft width_6">
                      {cafDataupdated[7077].value === 'VSAT' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="VSAT"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      VSAT
                    </div>
                    <div className="fleft width_6">
                      {cafDataupdated[7077].value === 'FWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="FWA"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      FWA
                    </div>
                    <div className="fleft width13">
                      {cafDataupdated[7077].value === 'Others' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Others"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                      <input type="text" name="" className="form_col lm" />
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3"> Backup LM</div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'TDM' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="TDM"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      TDM
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'CEN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="CEN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      CEN
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'EoSDH' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="EoSDH"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      EoSDH
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'BWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="BWA"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BWA
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'Rdio P2p' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Rdio P2p"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Radio P2P
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'ISDN' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="ISDN"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      ISDN
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === '3G' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="3G"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      3G
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7079].value === 'GPRS' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="GPRS"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      GPRS
                    </div>
                    <div className="fleft width_6">
                      {cafDataupdated[7079].value === 'VSAT' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="VSAT"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      VSAT
                    </div>
                    <div className="fleft width_6">
                      {cafDataupdated[7079].value === 'FWA' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="FWA"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      FWA
                    </div>
                    <div className="fleft width13">
                      {cafDataupdated[7079].value === 'Nil' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Nil"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Nill
                      <input type="text" name="" className="form_col lm nill" />
                    </div>
                  </div>
                  <div className="check over_hid pd_t3 font9">
                    <div className="fleft width110px pad_t3">
                      {' '}
                      Customer end equipment*
                    </div>
                    <div className="fleft width12">
                      {cafDataupdated[7080].value === 'Router/Switch' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Router/Switch"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Router/Switch
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7080].value === 'Modem' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Modem"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Modem
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7080].value === 'Converter' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Converter"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Convertor
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7080].value === 'Others' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Others"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Others
                    </div>
                    <div className="fleft width30">
                      Qty &nbsp; &nbsp;
                      <input
                        className="form_col width50px"
                        type="text"
                        name=""
                        value={cafDataupdated[13114].value}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className="margin0">
                    # Detailed BOM / BOQ to be attached separately
                  </p>
                  <div className="check over_hid pd_t5 font9">
                    <div className="fleft width110px pad_t3">
                      Customer end Interface
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7082].value === 'V.35' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="V.35"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      V.35
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7082].value === 'G.703' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="G.703"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      G.703
                    </div>
                    <div className="fleft width15">
                      {cafDataupdated[7082].value === 'Ethernet Electrical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Ethernet Electrical"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernet Electrical
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7082].value === 'BNC' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="BNC"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      BNC
                    </div>
                    <div className="fleft width15">
                      {cafDataupdated[7082].value === 'Ethernet Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Ethernet Optical"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Ethernat Optical
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7082].value === 'Optical' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Optical"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Optical
                    </div>
                    <div className="fleft width13">
                      {cafDataupdated[7082].value === 'Other' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Other"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Nill
                      <input type="text" name="" className="form_col lm nill" />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width110px pad_t3">Dual Homing</div>
                    <div className="fleft width6">
                      {cafDataupdated[7083].value === 'Yes' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Yes"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Yes{' '}
                    </div>
                    <div className="fleft width6">
                      {cafDataupdated[7083].value === 'No' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="No"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      No
                    </div>
                    <div className="fleft width6 pd_t5"> if yes, than</div>
                    <div className="fleft width15 pd_t5">
                      {' '}
                      Service Provider Pop1
                    </div>
                    <div className="fleft width6">
                      <input
                        className="form_col width90"
                        type="text"
                        name="Service Provider Pop1_FR"
                        value={cafDataupdated[7084].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width24 pd_t5 textcenter">
                      {' '}
                      Servce Provider PoP2, if dual homing
                    </div>
                    <div className="fleft width13">
                      <input
                        type="text"
                        name="Servce Provider PoP2"
                        value={cafDataupdated[7085].value}
                        className="form_col width90"
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Primary Last Mile Service Provider{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name="Primary Last Mile Service Provider_FR"
                        value={cafDataupdated[7086].value}
                        className="form_col width90"
                      />
                    </div>
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Secondary Last Mile Service Provider{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name="Secondary Last Mile Service Provider_FR"
                        value={cafDataupdated[7087].value}
                        className="form_col width90"
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Primary CIR Bandwidth in Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name="Primary CIR Bandwidth in Mbps/Kbps_FR"
                        value={cafDataupdated[7088].value}
                        className="form_col width90"
                      />
                    </div>
                    <div className="fleft width25 pd_t5 font9">
                      {' '}
                      Secondary CIR Bandwidth in Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name="Secondary CIR Bandwidth in Mbps/Kbps"
                        value={cafDataupdated[7089].value}
                        className="form_col width90"
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      Parallel Bandwidth Mbps/Kbps{' '}
                    </div>
                    <div className="fleft width25">
                      <input
                        type="text"
                        name="Parallel Bandwidth Mbps/Kbps"
                        value={cafDataupdated[7090].value}
                        className="form_col width90"
                      />
                    </div>
                    <div className="fleft pd_t5 font9">
                      {' '}
                      To be filled only if the secound link used on load sharing
                      basis{' '}
                    </div>
                  </div>
                  <div className="check over_hid pd_t5">
                    <div className="fleft width25 pd_t5">
                      {' '}
                      IP VPN port information{' '}
                    </div>
                    <div className="fleft width16 pd_t5 fon8">
                      {' '}
                      Port Size in Kbps/Mbps/Gbps{' '}
                    </div>
                    <div className="fleft width16">
                      <input
                        type="text"
                        name=" Port Size in Kbps/Mbps/Gbps"
                        value={cafDataupdated[7092].value}
                        className="form_col width90"
                      />
                    </div>
                    <div className="fleft width25 pd_t5 fon8 textcenter">
                      {' '}
                      Total MPLS Bandwidth (Mbps){' '}
                    </div>
                    <div className="fleft width16">
                      <input
                        type="text"
                        name="Total MPLS Bandwidth (Mbps)"
                        value={cafDataupdated[7093].value}
                        className="form_col width90 input_red"
                      />
                    </div>
                  </div>
                  <div className="check over_hid pd_t5 font9">
                    <div className="fleft width25 pad_t3"> CoS Type </div>
                    <div className="fleft width16">
                      {cafDataupdated[7094].value === 'Standard' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Standard"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Standard
                    </div>
                    <div className="fleft">
                      {cafDataupdated[7094].value === 'Customized' ? (
                        <input
                          className="form_col"
                          type="checkbox"
                          name="Customized"
                          value=""
                          checked
                        />
                      ) : (
                        <input
                          className="form_col"
                          type="checkbox"
                          name=""
                          value=""
                          disabled
                        />
                      )}
                      Customized
                    </div>
                  </div>
                  <p className="margin0">
                    If COS Type is customized, enter COS split in percentage
                  </p>
                  <div className="check over_hid pad_t10 font9 cos">
                    <div className="fleft width6 pad_t3"> </div>
                    <div className="fleft width10">
                      {' '}
                      Premium Plus
                      <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Premium Plus_SD"
                        value={cafDataupdated[7096].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Premium / CoS1 <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Premium / CoS1_SD"
                        value={cafDataupdated[7097].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Enhanced Plus / CoS2 <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Enhanced Plus / CoS2_SD"
                        value={cafDataupdated[7098].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Enhanced / CoS3 <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Enhanced / CoS3_SD"
                        value={cafDataupdated[7099].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Basic Plus
                      <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Basic Plus_SD"
                        value={cafDataupdated[7100].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Basic / CoS4 <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Basic / CoS4_SD"
                        value={cafDataupdated[7101].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10">
                      {' '}
                      Total <br />
                      <input
                        className="form_col width75"
                        type="text"
                        name="Total_SD"
                        value={cafDataupdated[7102].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width10 pad_t15">
                      {' '}
                      (Total should be 100%){' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <p className="customfom">
                Customer Application Form - Domestic MPLS Service
              </p>
              <div className="check over_hid pad_t10">
                <div className="fleft width170px pad_t3"> Encapsulation </div>
                <div className="fleft width12">
                  {cafDataupdated[7103].value === 'IP' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="IP"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  IP
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7103].value === 'Ethernet' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Ethernet"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Ethernet
                </div>
                <div className="fleft width16 pad_t3">
                  {cafDataupdated[7103].value === 'HDLC/PPP' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="HDLC/PPP"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  HDLC/PPP
                </div>
                <div className="fleft width30">
                  {cafDataupdated[7103].value === 'Other' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Other"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Other
                  <input
                    className="form_col width75 inputext top-3"
                    type="text"
                    name="Other1"
                    value={cafDataupdated[7104].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="check over_hid pad_red">
                <div className="fleft width170px pad_t3 pad_red">
                  {' '}
                  Routing Protocol{' '}
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7105].value === 'Static' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Static"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Static
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7105].value === 'BGP' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="BGP"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  BGP
                </div>
                <div className="fleft width52">
                  {cafDataupdated[7105].value === 'other' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="other"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Other
                  <input
                    className="form_col width75 inputext top-3 input_red"
                    type="text"
                    name="Other2"
                    value={cafDataupdated[7106].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  {' '}
                  If BGP, AS Number{' '}
                </div>
                <div className="fleft width52">
                  <input
                    className="form_col width75 top-3"
                    type="text"
                    name="If BGP, AS Number_SD"
                    value={cafDataupdated[7107].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="check over_hid">
                <div className="fleft width170px pad_t3">
                  {' '}
                  Self Care Service Variant{' '}
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7108].value === 'Silver' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Silver"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7108].value === 'Silver Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Silver Plus"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Sliver Plus
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7108].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7108].value === 'Gold Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold Plus"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold Plus
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7108].value === 'None' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold Plus"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  None
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  {' '}
                  Managed Service Varients{' '}
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7109].value === 'Silver' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Silver"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Silver
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7109].value === 'Gold' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7109].value === 'Gold Plus' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold Plus"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Gold Plus
                </div>
                <div className="fleft width12">
                  {cafDataupdated[7109].value === 'None' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Gold Plus"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  None
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3"> CPF Managed by </div>
                <div className="fleft width_12">
                  {cafDataupdated[7110].value === 'Vodafone Idea' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Vodafone Idea"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Vodafone Idea
                </div>
                <div className="fleft width_12">
                  {cafDataupdated[7110].value === 'Customer' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Customer"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customer
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  {' '}
                  CPF Maintaind by{' '}
                </div>
                <div className="fleft width_12">
                  {cafDataupdated[7111].value === 'Vodafone Idea' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Vodafone Idea"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Vodafone Idea
                </div>
                <div className="fleft width_12">
                  {cafDataupdated[7111].value === 'Customer' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Customer"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customer
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3"> SLA Type </div>
                <div className="fleft width_12">
                  {cafDataupdated[7112].value === 'Standard' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Standard"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Standard
                </div>
                <div className="fleft width_12">
                  {cafDataupdated[7112].value === 'Customized' ? (
                    <input
                      className="form_col"
                      type="checkbox"
                      name="Customized"
                      value=""
                      checked
                    />
                  ) : (
                    <input
                      className="form_col"
                      type="checkbox"
                      name=""
                      value=""
                      disabled
                    />
                  )}
                  Customized
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  {' '}
                  If SLA Type Customized
                </div>
                <div className="fleft width15">
                  Service Availability (%)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name="Service Availability_OD"
                    value={cafDataupdated[7114].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  MTTR (Hours)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name="MTTR_OD"
                    value={cafDataupdated[7115].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Latency (ms)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name="Latency_oD"
                    value={cafDataupdated[7116].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Packet Loss (%)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name="Packet_OD"
                    value={cafDataupdated[7117].value}
                    readOnly
                  />
                </div>
                <div className="fleft width15">
                  Jitter (ms)
                  <br />
                  <input
                    className="form_col width75"
                    type="text"
                    name="Jitter_OD"
                    value={cafDataupdated[7118].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="pad_t5">
                <p className="ligthbg">CHARGE DESCRIPTION</p>
              </div>
              <div className="char">
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    <strong>Specify Currency</strong>
                  </div>
                  <div className="fleft width7">
                    {cafDataupdated[7142].value === 'INR' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="INR"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    INR{' '}
                  </div>
                  <div className="fleft width7">
                    {cafDataupdated[7142].value === 'US$' ? (
                      <input
                        className="form_col"
                        type="checkbox"
                        name="US$"
                        value=""
                        checked
                      />
                    ) : (
                      <input
                        className="form_col"
                        type="checkbox"
                        name=""
                        value=""
                        disabled
                      />
                    )}
                    US${' '}
                  </div>
                  <div className="fleft width15 textcenter"> One Time (A) </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Annual Recurring (B){' '}
                  </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Security Deposit (C){' '}
                  </div>
                  <div className="fleft width15 textcenter">
                    {' '}
                    Grand Total (A+B+C){' '}
                  </div>
                </div>
                <div className="check over_hid">
                  <div className="fleft width170px pad_t3"> Service Rental</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 "
                      type="text"
                      name="A1"
                      value={cafDataupdated[7143].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 "
                      type="text"
                      name="B1"
                      value={cafDataupdated[7144].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C1"
                      value={cafDataupdated[7145].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R1"
                      value={cafDataupdated[7146].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    IP Address Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A2"
                      value={cafDataupdated[7147].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B2"
                      value={cafDataupdated[7148].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R2"
                      value={cafDataupdated[7149].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> SLA Charges</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A3"
                      value={cafDataupdated[7150].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B3"
                      value={cafDataupdated[7151].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R3"
                      value={cafDataupdated[7152].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Self Care Portal Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A4"
                      value={cafDataupdated[7153].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B4"
                      value={cafDataupdated[7154].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R4"
                      value={cafDataupdated[7155].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Managed Service Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A5"
                      value={cafDataupdated[7156].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B5"
                      value={cafDataupdated[7157].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R5"
                      value={cafDataupdated[7158].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Managed CPE Charges
                  </div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A6"
                      value={cafDataupdated[7159].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B6"
                      value={cafDataupdated[7160].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R6"
                      value={cafDataupdated[7161].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> CPE Rentals</div>
                  <div className="fleft width7">&nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A7"
                      value={cafDataupdated[7162].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B7"
                      value={cafDataupdated[7163].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C7"
                      value={cafDataupdated[7164].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R7"
                      value={cafDataupdated[7165].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 1 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A8"
                      value={cafDataupdated[7166].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B8"
                      value={cafDataupdated[7167].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C8"
                      value={cafDataupdated[7168].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R8"
                      value={cafDataupdated[7169].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 2 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A9"
                      value={cafDataupdated[7170].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B9"
                      value={cafDataupdated[7171].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C9"
                      value={cafDataupdated[7172].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R9"
                      value={cafDataupdated[7173].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 3 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A10"
                      value={cafDataupdated[7174].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B10"
                      value={cafDataupdated[7175].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C10"
                      value={cafDataupdated[7176].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R10"
                      value={cafDataupdated[7177].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 4 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A11"
                      value={cafDataupdated[7178].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B11"
                      value={cafDataupdated[7179].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C11"
                      value={cafDataupdated[7180].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R11"
                      value={cafDataupdated[7181].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3"> </div>
                  <div className="fleft width7 pad_t5">CPE 5 </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A12"
                      value={cafDataupdated[7182].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B12"
                      value={cafDataupdated[7183].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C12"
                      value={cafDataupdated[7184].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R12"
                      value={cafDataupdated[7185].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Miscellaneous Charges - 1{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A13"
                      value={cafDataupdated[7186].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B13"
                      value={cafDataupdated[7187].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="C13"
                      value={cafDataupdated[7188].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R13"
                      value={cafDataupdated[7189].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    Miscellaneous Charges - 2{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A14"
                      value={cafDataupdated[7190].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="B14"
                      value={cafDataupdated[7191].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R14"
                      value={cafDataupdated[7193].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Registration Charges{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="A15"
                      value={cafDataupdated[7194].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75"
                      type="text"
                      name="Total_R15"
                      value={cafDataupdated[7195].value}
                      readOnly
                    />
                  </div>
                </div>
                <div className="check over_hid pad_t5">
                  <div className="fleft width170px pad_t3">
                    {' '}
                    Total Amount Payable{' '}
                  </div>
                  <div className="fleft width7 pad_t5"> &nbsp; </div>
                  <div className="fleft width7"> &nbsp;</div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name="A16"
                      value={cafDataupdated[7196].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name="B16"
                      value={cafDataupdated[7197].value}
                      readOnly
                    />
                  </div>
                  <div className="fleft width15 textcenter"> &nbsp; </div>
                  <div className="fleft width15 textcenter">
                    <input
                      className="form_col width75 input_red"
                      type="text"
                      name="Total_R16"
                      value={cafDataupdated[7248].value}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="check over_hid pad_t5">
                <div className="fleft width170px pad_t3">
                  <strong> Total Order Value</strong>{' '}
                </div>
                <div className="fleft width7 pad_t5"> &nbsp; </div>
                <div className="fleft width7"> &nbsp;</div>
                <div className="fleft width45 mar_l7">
                  <input
                    className="form_col width90"
                    type="text"
                    name="Totalordervalue"
                    value={cafDataupdated[7200].value}
                    readOnly
                  />
                </div>
              </div>
              <div className="pad_t5">
                <p className="ligthbg">COMMENTS</p>
              </div>
              <p>
                <textarea
                  className="textarea"
                  disabled={true}
                  placeholder="Comments/Special Consideration:"
                >
                  {cafDataupdated[7119].value}
                </textarea>
              </p>
              <div className="margin0">
                <p className="ligthbg">IMPORTANT</p>
              </div>

              <div className="signate float_img">
                {firstCaps !== '' ? (
                  <div style={{ padding: '10px' }} className={Signcss}>
                    {firstCaps + '  .  '}
                    <span className="under_line">{secondBold}</span>
                  </div>
                ) : (
                  ''
                )}
                {imageData !== null && imageData !== '' ? (
                  <div style={{ padding: '10px' }} className="signate_img">
                    <img
                      src={
                        imageData !== null
                          ? 'data:' + imageType + ';base64,' + encode(imageData)
                          : ''
                      }
                      alt={'Company Seal'}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <p className="margin0">
                <strong>Customer Responsibility</strong>
              </p>
              <p className="margin0">
                Customer will be required to provide the following for site
                readlness
              </p>
              <div className="over_hid pad_t5">
                <div className="fleft width45 pad_t3">
                  Lockable, condition space for housing telecom equipment{' '}
                </div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <div className="over_hid">
                <div className="fleft width45 pad_t3">Power</div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <div className="over_hid">
                <div className="fleft width45 pad_t3">Earthing</div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <p className="margin0">
                Customer will be required to grant the following permissions to
                VIL and it's carrier partner
              </p>
              <div className="over_hid pad_t10">
                <div className="fleft width45 pad_t3">
                  Right of way permission
                </div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <div className="over_hid">
                <div className="fleft width45 pad_t3">
                  Roof top space for pole/tower installiton
                </div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <div className="over_hid">
                <div className="fleft width45 pad_t3">
                  Ground space for pole/tower installiton
                </div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>
              <div className="over_hid">
                <div className="fleft width45 pad_t3">
                  Site access to employees, authorised representatives of
                  <br />
                  VIL/its affiliaties the execution of their duty for
                  <br />
                  the purpose of installation and/or equipment configuration
                </div>
                <div className="fleft mar_l7">
                  <input
                    className="form_col width37px inputext_brd"
                    type="text"
                    name=""
                    value=""
                    readOnly
                  />
                </div>
              </div>

              <p className="margin0">
                <strong>Deemed Acceptance</strong>
              </p>
              <p className="margin0">
                Customer is excepted to provide acceptance of the Link(s) in 5
                days post delivery (subject to Hub location delivery) after
                which VIL start billing the customer for th Link(s). In absence
                of any shortcomings on the Link(s) performance or acceptance
                communication from the customer with in 5 days. VIL will deem
                the links as accepted by the customer and inititate billing
                effective 5 days from link delivery.
              </p>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="pad_t10">
                <p className="customfom">
                  Customer Application Form - Domestic MPLS Service
                </p>
              </div>
              <div className="over_hid">
                <div className="fleft width65 pad_t10">
                  <p className="marb5">Authorised Signatory's Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name="Authorised Signatory's Name"
                      value={cafDataupdated[7120].value}
                      readOnly
                    />
                  </p>
                  <div className="over_hid pad_t30">
                    <div className="fleft width40">
                      Designation
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name="Designation"
                        value={cafDataupdated[7121].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Place
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name="Place"
                        value={cafDataupdated[7122].value}
                        readOnly
                      />
                    </div>
                    <div className="fleft width30">
                      Date
                      <input
                        className="form_col width90 inputext design"
                        type="text"
                        name="Date"
                        value={cafDataupdated[7123].value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="flright width30">
                  <div className="signate" style={{ width: '225px' }}>
                    {firstCaps !== '' ? (
                      <div className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      Signature of the Customer / Authorised signatory with in
                      this box only (In case of institutinal, please affix
                      office/company seal){' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className="over_hid pad_t30">
                <div className="fleft width65 pad_t10">
                  <p className="marb5">Account Manager Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name="Account Manager Name"
                      value={cafDataupdated[7125].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Account Manager Circle Office </p>
                  <p className="margin0">
                    <input
                      className="form_col width50 inputext_brd_list"
                      type="text"
                      name="Account Manager Circle Office"
                      value={cafDataupdated[7126].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Channel Partner Name</p>
                  <p className="margin0">
                    <input
                      className="form_col widthfull inputext"
                      type="text"
                      name="Channel Partner Name"
                      value={cafDataupdated[7128].value}
                      readOnly
                    />
                  </p>
                  <p className="marb5">Channel Partner Code</p>
                  <p className="margin0">
                    <input
                      className="form_col width50 inputext_brd_list"
                      type="text"
                      name="Channel Partner Code"
                      value={cafDataupdated[7129].value}
                      readOnly
                    />
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>Vodafone Idea Limited</strong> ( Formerly Idea
                    Cellular Limited )<br />
                    An Adity Birla Group and Vodafone partneship
                    <br />
                    MergerCo CIN No: L32100GJ1996PLC030976
                    <br />
                    <strong>Registred Office:</strong> Suman Tower, Plot no. 18,
                    Sector 11, Gandhinagar - 382 011, Gujrat, T: +91 79 66714000
                    | F: +91 79 23232251
                    <br />
                    <strong>Circle Office Address:</strong>{' '}
                  </p>
                </div>
                <div className="flright width30">
                  <div
                    className="signate"
                    style={{ marginTop: '100px', width: '225px' }}
                  >
                    {acctMngSignImageData !== null &&
                    acctMngSignImageData !== '' ? (
                      <div className="signate_img input_sign">
                        <img
                          src={
                            acctMngSignImageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(acctMngSignImageData)
                              : ''
                          }
                          alt={'Account Manager Sign'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div>Signature of the Account Manager </div>
                  </div>
                </div>
              </div>
              <div className="flright width30">
                <div className="signate">
                  {imageData !== null && imageData !== '' ? (
                    <div className="signate_img">
                      <img
                        src={
                          imageData !== null
                            ? 'data:' +
                              imageType +
                              ';base64,' +
                              encode(imageData)
                            : ''
                        }
                        alt={'Company Seal'}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div>Customer Company Seal</div>
                </div>
              </div>
            </div>
          </page>
          <page size="A4">
            <div className="section2 font8">
              <div className="">
                <div className="pad_b5">
                  <p className="customfom">
                    Customer Application Form - Domestic MPLS Service
                  </p>
                </div>
                <p className="ligthbg font8">
                  MPLS Service under Licence Terms & conditions{' '}
                </p>
                <p className="font10">
                  <strong>
                    This "Customer Application Form” (CAF) shall mean together
                    (1) the customer Information form; and (11) the following
                    conditions ("Regulatory Terms”), which are required to be
                    provided/completed with effect from /prior to activation of
                    MPLS VPN Services provided by
                  </strong>{' '}
                </p>
                <div className="regtext">
                  Regulatory terms governing mpls vpn leased line services
                </div>
                <div className="over_hid">
                  <div className="fleft width49">
                    <ul className="list">
                      <li>
                        1. The Customer undertakes to fully comply with all the
                        applicable laws and regulations in force from time to
                        time (Including but not limited to Indian telegraph Act
                        1885, Indian telegraph rules 1951, Information
                        Technology Act 2000 and amendments thereof, TRAI Act
                        1997) governing the use of Telecom services provided
                        under this CAF.{' '}
                      </li>
                      <li>
                        2. The Customer shall strictly comply with MPLS VPN
                        architecture and shall ensure the bonafide use of the
                        leased circuits provided under this CAF.{' '}
                      </li>
                      <li>
                        3. The Customer undertakes to provide the Certified copy
                        of their OSP Registration or NLD License or any other
                        Telecom License in case they are holding the same or
                        become holder of the same at any point of time, while
                        availing the national private leased circuits from VIL.{' '}
                      </li>
                      <li>
                        4. The Customer agrees and undertakes that the leased
                        circuits provided to him shall not be used for any of
                        the activities mentioned hereunder -<br></br>• Voice
                        Telephony (PSTN /ISDN/SIP Trunking/PLMN).
                        <br></br>• Connectivity of the leased circuits with any
                        PLMN/PSTN/Public Network
                        <br></br>• Reselling of the leased circuits (not
                        applicable if customer holding NLD License or UL VNO
                        with NLD Authorisation)
                      </li>
                      <li>
                        5. The Customer must ensure that Service provided, is
                        not used for any unlawful purposes. The Customer shall
                        ensure that objectionable, obscene, unauthorized or any
                        other content, messages or communications infringing
                        copyright Intellectual property right and international
                        & domestic cyber laws, in any form or inconsistent with
                        the laws of India, are not carried in their network by
                        them or any other authorized person using their network
                        or during the provisioning of Services. The Customer
                        must take all necessary measures to prevent such use.
                        The use of the Service for anti-national activities
                        shall be construed as an offence punishable under the
                        Indian Penal Code or other applicable law.{' '}
                      </li>
                      <li>
                        6. Customer shall allow VIL employees or its authorized
                        personnel, enter at all times into the premises where
                        the Services are provided for periodical inspection,
                        installi replacing and removing equipment hardware
                        and/or software prior to, during and after the provision
                        of the services.
                      </li>
                    </ul>
                  </div>
                  <div className="fright width49">
                    <ul className="list">
                      <li>
                        7. VIL warrants that the Services shall be of the
                        acceptable grade, consistent with the established and
                        generally accepted standards. However, the quality,
                        functionality, availability or reliability of the same
                        may be affected from time to time.{' '}
                      </li>
                      <li>
                        8.VIL shall be entitled to collect/recover financial
                        penalty, if any, from the Customer as may be levied by
                        Government on it on account of any breach of regulatory
                        terms in this CAF and shall also be entitled, without
                        any liability, to refuse, limit, suspend, vary,
                        disconnect and or interrupt the Services, in whole or in
                        part, at any time, for any reason and/or due to various
                        factors including but not limited to: . Goverment's
                        rules, regulations, orders, directions, etc.
                        <br></br>• Combat potential fraud, sabotage, etc.
                        <br></br>• Force-Majeure circumstances.
                      </li>
                      <li>
                        9. VIL may change, amend or revise the above regulatory
                        terms and conditions at any time as and when necessary
                        in order to comply with any statutory, legal or
                        regulatory requirements and the Customer agrees to abide
                        by such modified terms and conditions. Such changes,
                        amendments or revisions shall be deemed effective upon
                        posting an updated and duly dated regulatory compliance
                        to the Customer via email, fax, post or through any
                        other medium opted by VIL{' '}
                      </li>
                      <li>
                        10. Customer has read, understands and agrees to VIL
                        Privacy Policy which is available at
                        https://www.vodafone.in/privacy-policy.
                      </li>
                      <li>
                        11. The Regulatory Terms under this CAF binds the
                        Customer, their executors, administrators,successors and
                        permitted assignees to the terms & conditions of the
                        CAF, along with the Terms & conditions of MSA & SLA (if
                        any).{' '}
                      </li>
                      <li>
                        12. GST & SEZ STATUS: The customer will be solely
                        responsible for providing the correct GSTnumber and
                        "SEZ" related information against the "Place of Supply"
                        as per the GST Law.S/he waives off all the rights to
                        dispute or withhold any payments in case of incorrect
                        GST Numbers or incorrect SEZ certificates/ status being
                        provided, which don't match the details available on the
                        Government GSTN portal. The customer acknowledges that
                        s/he will not be able to claim the GST tax credit under
                        such circumstances and shall pay 100% invoiced amount,
                        including taxes.{' '}
                      </li>
                    </ul>
                    <div className="signate float_img">
                      {firstCaps !== '' ? (
                        <div style={{ padding: '10px' }} className={Signcss}>
                          {firstCaps + '  .  '}
                          <span className="under_line">{secondBold}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {imageData !== null && imageData !== '' ? (
                        <div
                          style={{ padding: '10px' }}
                          className="signate_img"
                        >
                          <img
                            src={
                              imageData !== null
                                ? 'data:' +
                                  imageType +
                                  ';base64,' +
                                  encode(imageData)
                                : ''
                            }
                            alt={'Company Seal'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </page>
          <div>
            {excelJson !== null ? (
              <page size="A4">
                <div>
                  <div className="pad_t10 section2">
                    <p className="customfom">
                      Annexure to MPLS CAF - Site details basis CAF number :-{' '}
                    </p>
                  </div>
                  <JsonToTable json={excelJson} />
                  <div>
                    <p class="font10 " style={{ marginTop: '20px' }}>
                      <strong>Note:</strong> Address proofs will be required for
                      each of the sites listed above as part of standard
                      documentation guidelines; Master CAF referred here should
                      be signed by the same
                    </p>
                  </div>

                  <div class="fleft width50 pad_t10">
                    <p class="marb5">Account Manager Name</p>
                    <p class="margin0">
                      <input
                        class="form_col widthfull inputext"
                        type="text"
                        name="Account Manager Name"
                        readonly=""
                        value={cafDataupdated[7125].value}
                      />
                    </p>
                    <div className="signate" style={{ position: 'relative' }}>
                      {acctMngSignImageData !== null &&
                      acctMngSignImageData !== '' ? (
                        <div className="signate_img input_sign">
                          <img
                            src={
                              acctMngSignImageData !== null
                                ? 'data:' +
                                  imageType +
                                  ';base64,' +
                                  encode(acctMngSignImageData)
                                : ''
                            }
                            alt={'Account Manager Sign'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div>Signature of the Account Manager </div>
                    </div>
                  </div>
                  <div
                    className="signate float_img"
                    style={{ position: 'initial' }}
                  >
                    <div className="over_hid ">
                      <div className="over_hid ">
                        Authorised Signatory Name
                        <input
                          className="form_col inputext"
                          type="text"
                          name="Name_CD"
                          value={cafDataupdated[7245].value}
                          readOnly
                        />
                      </div>
                    </div>
                    {firstCaps !== '' ? (
                      <div style={{ padding: '10px' }} className={Signcss}>
                        {firstCaps + '  .  '}
                        <span className="under_line">{secondBold}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {imageData !== null && imageData !== '' ? (
                      <div style={{ padding: '10px' }} className="signate_img">
                        <img
                          src={
                            imageData !== null
                              ? 'data:' +
                                imageType +
                                ';base64,' +
                                encode(imageData)
                              : ''
                          }
                          alt={'Company Seal'}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </page>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  } else {
    cafContentView = cafData ? (
      <div>
        <Center p={2} my={1}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor={chakraTheme.colors.secondary}
            color={chakraTheme.colors.brand[800]}
            size="md"
          />
        </Center>
      </div>
    ) : (
      <div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
        {' '}
        CAF Form not submitted yet.
      </div>
    );
  }
  return <div>{cafContentView}</div>;
};

export default CafForm;
